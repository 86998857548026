<template>

  <el-form ref="dataForm" :model="dataForm" label-width="120px" label-position="left" class="auditForm">
    <div class="certifyLogo " v-if="dataForm.applyState==0">
      <img src="../../assets/image/wait.png" alt="">
      <h2>认证中</h2>
    </div>
    <div class="certifyLogo " v-if="dataForm.applyState==1">
      <img src="../../assets/image/success.png" alt="">
      <h2>认证成功</h2>
    </div>
    <div class="certifyLogo " v-if="dataForm.applyState==2">
      <img src="../../assets/image/fail.png" alt="">
      <h2>认证失败，请重新认证</h2>
    </div>
    <div class="failReason" v-if="dataForm.applyState==2">
      <p class="reason">失败原因：{{ dataForm.remark }}</p>
    </div>
    <div class="content">
      <p class="certItem"><span class="certName">企业名称：</span><span class="certContent">{{ dataForm.fullName }}</span>
      </p>
      <p class="certItem"><span class="certName">证件类型：</span><span class="certContent">{{ dataForm.certType }}</span>
      </p>
      <p class="certItem"><span class="certName">证件号码：</span><span class="certContent">{{ dataForm.code }}</span></p>
      <p class="certItem"><span class="certName">单位类型：</span><span class="certContent">{{ dataForm.orgType }}</span></p>
      <p class="certItem"><span class="certName">单位级别：</span><span class="certContent">{{ dataForm.level }}</span></p>
      <p class="certItem" v-if="dataForm.orgType=='社团组织'">
        <span class="certName">体育项目：</span>
        <el-checkbox-group v-model="sportItem" disabled>
          <el-checkbox v-for="(item,index) in sportItemList"
                       :key="index"
                       :label="item">
          </el-checkbox>
        </el-checkbox-group>
      </p>


      <p class="certItem"><span class="certName">地区：</span><span>{{ dataForm.province }}</span></p>
      <p class="certItem"><span class="certName">地址：</span><span>{{ dataForm.address }}</span></p>
      <p class="certItem certImg">
        <span class="certName">证件照：</span>
        <el-image
            style="width: 152px; height: 90px; "
            :src="loadUrl(dataForm.imgUrl)"
            :preview-src-list="imgList"

        >
        </el-image>
      </p>
      <p class="certItem certImg">
        <span class="certName">单位标识：</span>
        <el-image
            style="width: 90px; height: 90px; "
            :src="loadUrl(dataForm.logo)"
            :preview-src-list="logoList"

        >
        </el-image>
      </p>
      <p class="certItem"><span class="certName">认证人身份：</span><span>{{
          dataForm.personType == 1 ? '法人代表' : '被授权人'
        }}</span></p>

      <p class="certItem"><span class="certName">法定代表人：</span><span>{{ dataForm.corporateName }}</span></p>
      <p class="certItem"><span class="certName">法定代表人身份证号：</span><span>{{ dataForm.corporateId }}</span></p>
      <p class="certItem"><span class="certName">联系人：</span><span>{{ dataForm.name }}</span></p>
      <p class="certItem"><span class="certName">联系电话：</span><span>{{ dataForm.phone }}</span></p>
      <p class="certItem certImg" v-if="dataForm.personType==1">
        <span class="certName">法人证书：</span>
        <el-image
            style="width: 152px; height: 90px;  "
            :src="loadUrl(dataForm.certImg)"
            :preview-src-list="certList"
        >
        </el-image>
      </p>
      <p class="certItem certImg" v-else>
        <span class="certName">授权附件：</span>
        <el-image
            style="width: 152px; height: 90px;  "
            :src="loadUrl(dataForm.certImg)"
            :preview-src-list="certList"

        >
        </el-image>
      </p>
    </div>

  </el-form>


</template>

<script>
import {
  getApplyUnitList,
} from '@/api/certify'
import {getUserInfo} from "@/api/user";


export default {
  props: ['id'],
  data() {
    return {
      checked: false,
      btnLoading: false,
      formLoading: false,


      itemId: '',
      dataForm: {
        fullName: '', //企业单位名称
        certType: '', //企业证件类型统一社会信用代码、企业营业执照、组织机构代码证、事业单位法人证书、社会团体法人登记证书、行政执法主体资格证）
        code: "",//统一社会信用代码
        orgType: '',//单位类型（机关单位、事业单位、企业、社团组织）
        imgUrl: '', //上传的证件
        personType: '2', //法人1 被授权人2
        corporateName: '', //法人姓名
        corporateId: '', //法人身份证
        certImg: '',//授权证书
        level: '',//单位级别（省、市、区）
        name: '',//联系人
        phone: '',//联系电话
        province: '',//行政区划（省/市/区）
        address: '', //地址
        unitSportEntities: [], //体育项目（单位类型为“社团组织”才需填写,多选
        userId: '', //用户id

      },

      certTypeList: [
        {label: '企业营业执照', value: '1'},
        {label: '组织机构代码证', value: '2'},
        {label: '事业单位法人证书', value: '3'},
        {label: '社会团体法人登记书', value: '4'},
        {label: '行政执法主体资格证', value: '5'},
      ],
      sportItemList: [
        '足球', '篮球', '马术', '武术'
      ],

      sportItem: [],
      certList: [],
      imgList: [],
      logoList: []

    }
  },
  mounted() {
    this.init()
  },
  watch: {
    id: function () {
      this.init()
    }
  },
  methods: {
    init() {
      let param = {
        currentPage: "1",
        pageSize: "20",
        id: this.id
      }
      console.log(this.id)
      getApplyUnitList(param).then(res => {
        this.dataForm = res.data.list[0]
        this.dataForm.personType = res.data.list[0].personType.toString()
        this.dataForm.unitSportEntities = res.data.list[0].unitSportEntities
        for (let i = 0; i < res.data.list[0].unitSportEntities.length; i++) {
          this.sportItem.push(res.data.list[0].unitSportEntities[i].sport_name)
        }

        if (res.data.list[0].applyState != 0) {
          this.checked = true
        }

        this.certList.push(this.loadUrl(res.data.list[0].certImg))
        this.imgList.push(this.loadUrl(res.data.list[0].imgUrl))
        this.logoList.push(this.loadUrl(res.data.list[0].logo))
      }).catch(() => {
        this.formLoading = false
      })
    }


  },


}
</script>

<style lang="scss" scoped>
.auditForm {
  margin: 20px;

  .bold {


    h2 {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 12px;
      margin-bottom: 30px;
      border-bottom: 1px solid #ECECEC;
    }
  }

  .certifyLogo {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    img {
      padding-right: 16px;
      width: 44px;
      height: 44px;
    }
  }

  .certItem {
    padding-bottom: 20px;
    display: flex;

    .certName {
      display: inline-block;
      width: 100px;
      margin-right: 20px;
    }


  }

  //.certImg {
  //  display: flex;
  //  align-items: start;
  //}


  .failReason {
    width: 490px;
    height: 84px;
    background: #FFF8F8;
    color: #FF3F35;
    margin-bottom: 30px;

    .reason {
      padding: 20px 9px;
    }
  }

  ::v-deep .el-input {
    width: 555px;
  }

  ::v-deep.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-image: url("../../assets/image/cert.svg");
    background-size: 278px 158px;
    background-repeat: no-repeat;
    background-position: center;

  }

  ::v-deep.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 328px;
    height: 208px;
    line-height: 360px;
    //text-align: center;
    fill: #C2C2C2;

    :hover {
      fill: #409EFF;
    }
  }

  .avatar-uploader-icon :hover {
    fill: #409EFF;
  }

  .avatar {
    width: 328px;
    height: 208px;
    display: block;
  }

  .large {
    width: 400px;
    object-fit: contain;
  }

  .auditBtn {
    ::v-deep .el-button {
      width: 104px;
      height: 34px;
      line-height: 9px;
    }

    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
      margin-top: 22px;
    }
  }

  ::v-deep.el-upload__tip {
    margin-top: 0;

  }


  .remark, .download {
    color: #409EFF;
    cursor: pointer;
  }

  .auditImg {
    width: 328px;
    height: 208px;
    //max-height: 400px;

  }
}

</style>
