import request from '@/util/request'

// 申请单位
export const applyUnit = (data) => {
    return request({
        url: '/api/system/Base/ApplyUnit/add',
        method: 'POST',
        data
    })
}
//修改企业资格
export const updateUnit = (data) => {
    return request({
        url: '/api/system/Base/ApplyUnit/update',
        method: 'PUT',
        data
    })
}


//上传证件
export const uploadAvatar = (data) => {
    return request({
        url: 'api/file/Uploader/annexpic',
        method: 'POST',
        data
    })
}

//获取申请企业的列表
export const getApplyUnitList = (data) => {
    return request({
        url: 'api/system/Base/ApplyUnit/getList',
        method: 'POST',
        data
    })
}

//审核企业资格
export const auditing = (data) => {
    return request({
        url: '/api/system/Base/ApplyAudit/add',
        method: 'POST',
        data
    })
}




//检查认证企业是否存在
export const checkUnit = (name,data) => {
    return request({
        url: `/api/system/Base/ApplyUnit/applyUnitName/${name}`,
        method: 'GET',
        data
    })
}


