<template>
  <div class="user-content">
    <div class="bold ">
      <h2>单位认证</h2>
    </div>
    <el-button @click="link('add')" class="certify-btn">企业认证</el-button>
    <div class="myCertification">
      <el-scrollbar class="u-STB">
      <ul>
        <li class="cert" v-for="(item,index) in certList" :key="index" @click="detail(item.id)">
          <div class="content">
            <h2 class="title">{{ item.fullName }}</h2>
            <p class="certItem">证件类型：{{ item.orgType }}</p>
            <p class="certItem">证件号码：{{ item.code }}</p>
            <p class="certItem">申请日期：{{ item.createTime.split('T')[0] }}</p>
            <p class="certItem">状态：
              <span v-if="item.applyState==1" class="success">认证成功</span>
              <span v-if="item.applyState==2" class="danger">认证失败</span>
              <span v-if="item.applyState==0" class="warning">认证中</span>
            </p>
            <div class="btn">
              <el-button v-if="item.applyState==2" size="small" type="primary" @click="link('update',item.id)">重新认证</el-button>
            </div>

          </div>

        </li>

      </ul>
      </el-scrollbar>
      <el-dialog
          title="详情"
          :visible.sync="showDetail"
          width="548px"
      >
       <el-scrollbar  class="u-dialog">
        <certify-detail :id="itemId"/>
       </el-scrollbar>
      </el-dialog>

    </div>
   
  </div>
</template>

<script>
import {getApplyUnitList} from "@/api/certify";
import {getUserInfo} from "@/api/user";
import certifyDetail from "@/pages/user/certifyDetail";

export default {
  components: {certifyDetail},
  data() {
    return {
      userId: '',
      certList: [],
      showDetail: false,
      itemId: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getUserInfo().then((res) => {
        this.userId = res.data.id
        let param = {
          currentPage: "1",
          pageSize: "20",
          userId: this.userId,
        }
        getApplyUnitList(param).then((res) => {
          if (res.code === 200) {
            this.certList = res.data.list
          }
        })
      })

    },
    link(type,id) {
      if(type==='update'){
        this.$router.push(
            {
              path:'/certify',
              query: {id}
            }
        )
      }else if(type==='add'){
        this.$router.push('/certify')
      }
    },
    detail(id) {
      this.showDetail = true
      this.itemId = id
      console.log(this.itemId)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-content {

  .bold {
    h2 {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 12px;
      padding-top: 20px;
      margin-bottom: 24px;
      line-height: 24px;
      border-bottom: 1px solid #ECECEC;
    }
  }

  .certify-btn {
    width: 104px;
    height: 38px;
    background: #1E50AE;
    border-radius: 6px;
    color: #FFFFFF;
  }

  .myCertification {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -16px;

      .cert {
        width: 418px;
        height: 260px;
        background-image: url("../../assets/image/card.png");
        background-size: 418px 260px;
        cursor: pointer;

        .content {
          padding-left: 32px;
          padding-top: 50px;

          .title {
            font-size: 18px;
            color: #333333;
            padding-bottom: 10px;
          }

          .certItem {
            font-size: 14px;
            color: #999999;
            margin: 8px 0;

            .success {
              color: #5BC001;
              font-size: 16px;
            }

            .danger {
              color: #FF3F35;
              font-size: 16px;

            }

            .warning {
              color: #FF8740;
              font-size: 16px;

            }
          }
        }
      }
      .btn{
        text-align: right;
        margin-right: 36px;
      }

    }

    ::v-deep.el-dialog {
      border-radius: 10px;
      color: #333333;

      .el-dialog__header {
        border-bottom: 1px solid #E5E5E5;
      }

      .el-dialog__body {
        padding: 10px 10px;
      }
    }

  }
}

</style>
